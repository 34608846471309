import Model from '@/models/model.js';
import {empty} from '@/utils/miscUtils.js';

/**
 * standing team model class
 */
export default class TournamentStandingTableModel extends Model {
    /**
     * team id
     *
     * @type {number}
     */
    teamId = 0;

    /**
     * forced order
     *
     * @type {number}
     */
    forceOrder = 0;

    /**
     * points to be deducted
     *
     * @type {number}
     */
    deductPoints = 0;

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.tournamentStandingId = data.tournamentStandingId || 0;
            this.bp = data.bonusPoints || 0;
            this.d = data.drawn || 0;
            this.l = data.lost || 0;
            this.order = data.order || 0;
            this.m = data.played || 0;
            this.pts = data.points || 0;
            this.pa = data.pointsAgainst || 0;
            this.pb = data.pointsBonus || 0;
            this.pd = data.pointsDifference || 0;
            this.pf = data.pointsFor || 0;
            this.redCards = data.redCards || 0;
            this.teamAliasEqId = data.teamAliasEqId || '';
            this.teamCode = data.teamCode || '';
            this.teamName = data.teamName || '';
            this.tf = data.tries || 0;
            this.ta = data.triesAgainst || 0;
            this.tb = data.tryBonus || 0;
            this.td = (data.tries ?? 0) - (data.triesAgainst ?? 0);
            this.w = data.won || 0;
            this.yellowCards = data.yellowCards || 0;
        }
    }
}
