<template>
    <b-table-simple hover small caption-top responsive>
        <b-thead>
            <b-tr>
                <b-th colspan="6" class="text-uppercase text-center">
                    <span class="header">{{ title }}</span>
                    </b-th>
            </b-tr>
            <b-tr>
                <b-th class="text-uppercase text-center">#</b-th>
                <b-th class="text-uppercase text-center">{{ isPoolPhase ? 'Pool' : 'Match' }}</b-th>
                <b-th class="text-uppercase text-center">KO</b-th>
                <b-th class="text-uppercase text-center">Team 1</b-th>
                <b-th class="text-uppercase text-center">Score</b-th>
                <b-th class="text-uppercase text-center">Team 2</b-th>
            </b-tr>
        </b-thead>
        <b-tbody>
            <b-tr v-for="match in matches" v-bind:key="match.eqId">
                <b-td class="text-center">{{ match.matchNumber }}</b-td>
                <b-td class="text-center">{{ isPoolPhase ? match.group : match.stageName }}</b-td>
                <b-td class="text-center">{{ formatKickOff(match.kickOffTime) }}</b-td>
                <b-td class="text-center">{{ match.teamOneDisplayName }}</b-td>
                <b-td class="text-center">{{ match.teamOneScore }} - {{ match.teamTwoScore }}</b-td>
                <b-td class="text-center">{{ match.teamTwoDisplayName }}</b-td>
            </b-tr>
        </b-tbody>
    </b-table-simple>
</template>

<script>

export default {
    name: 'matchResultsTable',
    props: {
        matches: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        isPoolPhase: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
        }
    },
    methods: {
        formatKickOff(kickOffTime) {
            return kickOffTime.slice(0, -3)
        }
    },
}
</script>

<style lang="scss" scoped>
.header {
    font-size: 16px;
}
</style>
