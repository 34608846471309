<template>
    <div class="pageContent landscape-print">
        <div class="d-flex flex-row justify-content-between w-100">
            <div>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat mb-5"
                    v-bind:to="backToMatchDetailsLink"
                >
                    <icon type="arrow-left" class="align-middle" /> {{ $t('backToPreviousScreen') }}
                </actionButton>
            </div>
            <div>
                <actionButton variant="outline-primary" class="btn-flat no-print" v-on:click="printSegment()">
                    {{ $t('print') }}
                </actionButton>
            </div>
        </div>
        <wrapper
            v-bind:waitFor="apiData.teamsheets"
            v-bind:waitForRetry="reloadTeamsheets"
            noPadding noLine noShadow waitSilent
        >
            <div class="d-flex flex-row justify-content-center align-items-center tableGap p-mt-10px"
                v-bind:class="{ 'pb-6': isSmallOrBelow, 'pb-1': !isSmallOrBelow }"
            >
                <img v-bind:src="tournamentLogoUrl" height="50" />
                <h1>{{ matchTournamentName }}</h1>
            </div>
            <div class="d-flex w-100 justify-content-center" v-if="match && match.stageName">
                <span class="text-uppercase pb-4">{{ match.stageName }}</span>
            </div>
            <div
                class="d-flex align-items-center justify-content-between"
                v-bind:class="{ 'flex-column': isVSmallOrBelow, 'flex-row': !isVSmallOrBelow }"
                v-if="isSmallOrBelow"
            >
                <div class="text-nowrap wMinContent" v-if="venueName">
                    <span class="headerText text-uppercase">{{ venueName }}</span>
                </div>
                <div>
                    <span class="headerText text-uppercase">{{ team1Score }}:{{ team2Score }}</span>
                </div>
                <div>
                    <span class="headerText text-uppercase">{{  formattedKickOffDate }}</span>
                </div>
            </div>
            <div class="position-relative d-flex flex-row align-items-center justify-content-between pb-3" v-else>
                <div v-if="venueName">
                    <span class="headerText text-uppercase p-ml-10px">{{ venueName }}</span>
                </div>
                <div class="flex-grow-1 text-center"/> <!-- Empty div to maintain the layout -->
                <div>
                    <span class="headerText text-uppercase p-mr-10px">{{  formattedKickOffDate }}</span>
                </div>
                <div class="position-absolute w-100 text-center overlay">
                    <span class="headerText text-uppercase">{{ team1Score }}:{{ team2Score }}</span>
                </div>
            </div>
            <div
                class="d-flex tableGap w-100"
                v-bind:class="{ 'flex-row': !isSmallOrBelow, 'flex-column': isSmallOrBelow }"
            >
                <div class="w-100 d-flex flex-column p-pl-10px">
                    <teamsheetTournamentTable7s
                        v-bind:players="team1Lineup"
                        v-bind:isSub="false"
                        v-bind:scoreData="team1Scorings"
                        v-bind:disciplinaries="team1Disciplinaries"
                        v-bind:matchKickOffDate="match.kickOff"
                        v-bind:teamName="team1Name"
                        v-bind:teamLogoUrl="team1Logo"
                    />
                    <div
                        class="mt-5 flex-display gap30 gap15 no-print"
                        v-bind:class="{ 'flex-row': !isVSmallOrBelow, 'flex-column': isVSmallOrBelow }"
                    >
                        <div v-bind:class="{ 'pb-4': isSmallOrBelow && !isVSmallOrBelow }">
                            <TeamPersonnelTable7s v-bind:teamPersonnel="team1Personnel" />
                        </div>
                        <div>
                            <resultTable7s
                                v-bind:items="team1PreviousMatches"
                                v-bind:classes="{ wMinContent: isVSmallOrBelow, 'text-nowrap': isVSmallOrBelow }"
                            />
                        </div>
                    </div>
                </div>
                <div class="w-100 d-flex flex-column">
                    <teamsheetTournamentTable7s
                        v-bind:players="team2Lineup"
                        v-bind:isSub="false"
                        v-bind:scoreData="team2Scorings"
                        v-bind:disciplinaries="team2Disciplinaries"
                        v-bind:matchKickOffDate="match.kickOff"
                        v-bind:teamName="team2Name"
                        v-bind:teamLogoUrl="team2Logo"
                    />
                    <div
                        class="mt-5 flex-display gap30 gap15 p-end no-print"
                        v-bind:class="{ 'flex-row': !isVSmallOrBelow, 'flex-column': isVSmallOrBelow }"
                    >
                        <div v-bind:class="{ 'pb-4': isSmallOrBelow && !isVSmallOrBelow }">
                            <TeamPersonnelTable7s v-bind:teamPersonnel="team2Personnel" />
                        </div>
                        <div>
                            <resultTable7s
                                v-bind:items="team2PreviousMatches"
                                v-bind:classes="{ wMinContent: isVSmallOrBelow, 'text-nowrap': isVSmallOrBelow }"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-display flex-row justify-content-center pd-2rem p-pb-1rem w-100 no-print">
                <div>
                    <matchOfficialsTable7s v-bind:matchOfficials="matchOfficials"  />
                </div>
            </div>

            <div class="flex-display flex-row pt-3 justify-content-center w-100 no-print">
                <h5>F Forward | B Back | R Replacement</h5>
            </div>
            <!-- PRINT TABLES TEAM AND MATCH PERSONNEL -->
            <div class="flex-display flex-row w-100 justify-content-between no-screen p-pr-10px p-pl-10px p-pt-15px">
                <!-- Team 1 tables -->
                <div
                    class="mt-5 flex-display gap30 gap15"
                    v-bind:class="{ 'flex-row': !isVSmallOrBelow, 'flex-column': isVSmallOrBelow }"
                >
                    <div class="flex2">
                        <TeamPersonnelTable7s v-bind:teamPersonnel="team1Personnel" />
                    </div>
                    <div class="flex1">
                        <resultTable7s v-bind:items="team1PreviousMatches" />
                    </div>
                </div>
                <!-- Match Officials -->
                <div class="p-pt-15px">
                    <matchOfficialsTable7s v-bind:matchOfficials="matchOfficials" />
                </div>
                <!-- Team 2 tables -->
                <div
                    class="mt-5 flex-display gap30 gap15"
                    v-bind:class="{ 'flex-row': !isVSmallOrBelow, 'flex-column': isVSmallOrBelow }"
                >
                    <div class="flex2">
                        <TeamPersonnelTable7s v-bind:teamPersonnel="team2Personnel" />
                    </div>
                    <div class="flex1">
                        <resultTable7s v-bind:items="team2PreviousMatches" />
                    </div>
                </div>
            </div>
            <div class="flex-display flex-row p-pt-10px justify-content-center w-100 no-screen">
                <h5>F Forward | B Back | R Replacement</h5>
            </div>
        </wrapper>
    </div>
</template>

<script>
import icon from '@/components/icon.vue';
import teamsheetTournamentTable7s from '@/components/tables/7sTeamsheetTournamentTable.vue';
import resultTable7s from '@/components/tables/7sResultTable.vue';
import TeamPersonnelTable7s from '@/components/tables/7sTeamPersonnelTable.vue';
import MatchTeamsheetModel from '@/models/matchTeamsheet.js';
import matchOfficialsTable7s from '@/components/tables/7sMatchOfficialsTable.vue';
import { empty } from '@/utils/miscUtils.js';
import MatchModel from '@/models/match.js';
import base from '@/views/base.vue';
import Model from '@/models/model.js';
import { formatDateLong } from '@/utils/dateUtils';
import TournamentModel from '../../../models/tournament';


export default {
    name: 'commonTournamentTeamsheets7s',
    extends: base,
    components: {
        icon,
        teamsheetTournamentTable7s,
        TeamPersonnelTable7s,
        resultTable7s,
        matchOfficialsTable7s
    },
    props: {
        dataMatchId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        this.apiData = {
            match: this.loadMatch(),
        }
        return {
            apiData: {
                match: this.apiData.match,
                tournament: this.loadTournamentsForMatch(),
            },
            match: null,
            matchTournaments: [],
            matchOfficials: [],
            matchKickOffDate: null,
            matchTournamentName: '',
            matchTournamentLogoUrl: '',
            team1Name: '',
            team2Name: '',
            team1EqId: '',
            team2EqId: '',
            team1Logo: '',
            team2Logo: '',
            team1Score: 0,
            team2Score: 0,
            team1Last4Matches: [],
            team2Last4Matches: [],
            teamsheets: null,
            teamsheetTeam1Lineup: null,
            teamsheetTeam1Replacement: null,
            teamsheetTeam2Lineup: null,
            teamsheetTeam2Replacement: null,
            teamOneHeadCoach: '',
            teamTwoHeadCoach: '',
            teamOneAssistantCoach: '',
            teamTwoAssistantCoach: '',
            teamOneTeamDoctor: '',
            teamTwoTeamDoctor: '',
            teamOneMedicalOfficer: '',
            teamTwoMedicalOfficer: '',
            teamOnePhysio: '',
            teamTwoPhysio: '',
            teamOneStrengthC: '',
            teamTwoStrengthC: '',
            teamOneTeamManager: '',
            teamTwoTeamManager: '',
            teamOneVideoAnalyst: '',
            teamTwoVideoAnalyst: '',
            tournament: null,
            venueName: '',
            isSmallOrBelow: false,
            isVSmallOrBelow: false,
        };
    },
    computed: {
        backToMatchDetailsLink() {
            return {
                name: this.detailLinkRoute || 'commonMatchDetail',
                params: Object.assign({
                    dataMatchId: this.dataMatchId,
                }, this.detailLinkParams),
            };
        },
        team1Lineup() {
            return this.getLineup(0);
        },
        team2Lineup() {
            return this.getLineup(1);
        },
        team1Disciplinaries() {
            return this.getDisciplinaries(0);
        },
        team2Disciplinaries() {
            return this.getDisciplinaries(1);
        },
        team1Scorings() {
            return this.getScorings(0);
        },
        team2Scorings() {
            return this.getScorings(1);
        },
        team1Personnel() {
            const items = [];
            if (this.teamOneHeadCoach !== '') {
                items.push({ position: 'Head Coach', personName: this.teamOneHeadCoach });
            }
            if (this.teamOneAssistantCoach !== '') {
                items.push({ position: 'Assistant Coach', personName: this.teamOneAssistantCoach });
            }
            if (this.teamOneTeamManager !== '') {
                items.push({ position: 'Team Manager', personName: this.teamOneTeamManager });
            }
            if (this.teamOneTeamDoctor !== '') {
                items.push({ position: 'Team Doctor', personName: this.teamOneTeamDoctor });
            }
            if (this.teamOnePhysio !== '') {
                items.push({ position: 'Physiotherapist', personName: this.teamOnePhysio });
            }
            if (this.teamOneStrengthC !== '') {
                items.push({ position: 'Strength & Conditioning', personName: this.teamOneStrengthC });
            }
            return items;
        },
        team2Personnel() {
            const items = [];
            if (this.teamTwoHeadCoach !== '') {
                items.push({ position: 'Head Coach', personName: this.teamTwoHeadCoach });
            }
            if (this.teamTwoAssistantCoach !== '') {
                items.push({ position: 'Assistant Coach', personName: this.teamTwoAssistantCoach });
            }
            if (this.teamTwoTeamManager !== '') {
                items.push({ position: 'Team Manager', personName: this.teamTwoTeamManager });
            }
            if (this.teamTwoTeamDoctor !== '') {
                items.push({ position: 'Team Doctor', personName: this.teamTwoTeamDoctor });
            }
            if (this.teamTwoPhysio !== '') {
                items.push({ position: 'Physiotherapist', personName: this.teamTwoPhysio });
            }
            if (this.teamTwoStrengthC !== '') {
                items.push({ position: 'Strength & Conditioning', personName: this.teamTwoStrengthC });
            }
            return items;
        },
        team1PreviousMatches() {
            const matches = [];
            this.team1Last4Matches.forEach((matchData, i) => {
                matches.push(this.createMatchResult(matchData, this.team1EqId, i));
            });

            return matches;
        },
        team2PreviousMatches() {
            const matches = [];
            this.team2Last4Matches.forEach((matchData, i) => {
                matches.push(this.createMatchResult(matchData, this.team2EqId, i));
            });

            return matches;
        },
        formattedKickOffDate() {
            const formattedDate = formatDateLong(this.matchKickOffDate);
            return formattedDate;
        },
        tournamentLogoUrl() {
            if (!empty(this.matchTournamentLogoUrl)) {
                return Model._api.call.tournament.resolveStoragePath(this.matchTournamentLogoUrl);
            }
            return null;
        }
    },
    methods: {
        loadMatch() {
            return this.$api.call.match.matchGetById(this.dataMatchId).then(data => {
                this.match = new MatchModel(data);
                this.reloadTournamentsForMatch();
            });
        },
        loadTournamentsForMatch() {
            return this.$api.call.match.matchGetTournaments(this.dataMatchId).then(data => {
                this.tournament = new TournamentModel(data[0]);
                this.matchTournamentLogoUrl = data[0].logoUrl;
                this.reloadTeamsheets();
            });
        },
        reloadTournamentsForMatch() {
            this.apiData.tournament = this.loadTournamentsForMatch();
        },
        loadTeamsheets() {
            return this.$api.call.match.matchReportsTournamentTeamsheetsGetByMatchId(this.dataMatchId, this.tournament?.eqId).then(result => {
                this.teamsheets = new MatchTeamsheetModel(result);
                this.team1Name = result.teams[0].teamName;
                this.team2Name = result.teams[1].teamName;
                this.team1Logo = result.teams[0].teamLogoUrl;
                this.team2Logo = result.teams[1].teamLogoUrl;
                this.team1EqId = result.teams[0].teamEqId;
                this.team2EqId = result.teams[1].teamEqId;
                this.team1Score = result.teams[0].score;
                this.team2Score = result.teams[1].score;
                this.matchKickOffDate = result.match.kickOff;
                this.venueName = result.match.venueName;
                this.matchTournamentName = result.match.tournaments[0]?.tournamentName;

                this.teamOneHeadCoach = this.extractTeamPersonnel(0, 'Head Coach');
                this.teamTwoHeadCoach = this.extractTeamPersonnel(1, 'Head Coach');

                this.teamOneAssistantCoach = this.extractTeamPersonnel(0, 'Assistant Coach');
                this.teamTwoAssistantCoach = this.extractTeamPersonnel(1, 'Assistant Coach');

                this.teamOneTeamDoctor = this.extractTeamPersonnel(0, 'Team Doctor');
                this.teamTwoTeamDoctor = this.extractTeamPersonnel(1, 'Team Doctor');

                this.teamOneTeamManager = this.extractTeamPersonnel(0, 'Team Manager');
                this.teamTwoTeamManager = this.extractTeamPersonnel(1, 'Team Manager');

                this.teamOneMedicalOfficer = this.extractTeamPersonnel(0, 'Medical Officer');
                this.teamTwoMedicalOfficer = this.extractTeamPersonnel(1, 'Medical Officer');

                this.teamOnePhysio = this.extractTeamPersonnel(0, 'Physiotherapist');
                this.teamTwoPhysio = this.extractTeamPersonnel(1, 'Physiotherapist');

                this.teamOneStrengthC = this.extractTeamPersonnel(0, 'Strength & Conditioning');
                this.teamTwoStrengthC = this.extractTeamPersonnel(1, 'Strength & Conditioning');

                this.teamOneVideoAnalyst = this.extractTeamPersonnel(0, 'Video Analyst');
                this.teamTwoVideoAnalyst = this.extractTeamPersonnel(1, 'Video Analyst');

                this.match = this.teamsheets.match;

                return this.getMatchOfficials();
            });
        },
        loadLast4MatchesForTeam(teamEqId) {
            if (empty(teamEqId)) {
                return null;
            }

            const searchData = {
                dateUntil: this.matchKickOffDate,
                team1EqId: teamEqId,
                kickoffDateDirection: 'Desc',
                paging: {
                    pageIndex: 0,
                    pageSize: 4
                }
            };

            return this.$api.call.match.matchSearch(searchData)
                .then(result => {
                    return result.data;
                });
        },
        loadMatchesForTeams() {
            this.loadLast4MatchesForTeam(this.team1EqId).then((res) => { this.team1Last4Matches = res; })
            this.loadLast4MatchesForTeam(this.team2EqId).then((res) => { this.team2Last4Matches = res; })
        },
        reloadTeamsheets() {
            this.apiData.teamsheets = this.loadTeamsheets();
        },
        getMatchOfficials() {
            this.$api.call.match.matchOfficialsGetByMatchId(this.dataMatchId).then(result => {
                this.matchOfficials = (result || []).map(official => ({
                    position: official.positionName,
                    name: official.displayName,
                }));
            });
            return this.loadMatchesForTeams();
        },
        extractTeamPersonnel(teamIndex, positionName) {
            const teamPerson = this.teamsheets.teams[teamIndex].team.teamSheets.filter((item) => item.positionName === positionName);
            return teamPerson[0]?.displayName || '';
        },
        getLineup(teamIndex) {
            if (!this.teamsheets || this.teamsheets.teams.length < 2) {
                return [];
            }

            return this.teamsheets.teams[teamIndex].team.teamSheets;
        },
        getDisciplinaries(teamIndex) {
            if (!this.teamsheets || this.teamsheets.teams.length < 2) {
                return {};
            }

            const playerMap = {};
            const lineup = teamIndex === 1 ? this.team2Lineup : this.team1Lineup;
            lineup.forEach((p) => { playerMap[p.eqId] = { yellowCards: 0, redCards: 0 } });
            const disciplinaryData = this.teamsheets.teams[teamIndex].team.disciplinary;
            ['yellow', 'red'].forEach(cardType => {
                const disciplinaryList = disciplinaryData[cardType + 'Cards'] || [];

                for (let d of disciplinaryList) {
                    if (empty(d.playerEqId)) {
                        return;
                    }
                    const player = playerMap[d.playerEqId]
                    if (empty(player)) {
                        continue;
                    }
                    if (d.actionTypeName === 'Yellow Card') {
                        player.yellowCards++;
                    } else if (d.actionTypeName === 'Red Card') {
                        player.redCards++;
                    }
                }
            });
            const disciplinedPlayers = Object.fromEntries(
                Object.entries(playerMap).filter(([key, value]) =>
                    value.yellowCards > 0 || value.redCards > 0
                )
            )
            return disciplinedPlayers;
        },
        getScorings(teamIndex) {
            if (!this.teamsheets || this.teamsheets.teams.length < 2) {
                return {};
            }
            let playerMap = {};
            const scoreData = this.teamsheets.teams[teamIndex].team.scoring;
            const lineup = teamIndex === 1 ? this.team2Lineup : this.team1Lineup;
            lineup.forEach((p) => { playerMap[p.eqId] = { conversions: 0, tries: 0, dropGoals: 0, penalties: 0, points: 0 } });
            const scorings = {
                conversions: scoreData.conversions,
                dropGoals: scoreData.dropGoals,
                penalties: scoreData.penalties,
                tries: scoreData.tries,
            };
            for (let scoreType in scorings) {
                const data = scorings[scoreType];
                for(let d of data) {
                    if (empty(d)) {
                        continue;
                    }
                    const player = playerMap[d.playerEqId];
                    if (empty(player)){
                        continue;
                    }
                    switch(d.actionTypeName) {
                        case 'Conversion':
                            player.conversions++;
                            break;
                        case 'Try':
                            player.tries++;
                            break;
                        case 'Drop goal':
                            player.dropGoals++;
                            break;
                        case 'Penalty':
                            player.penalties++;
                            break;
                        default:
                            break;
                    }
                }
            };
            const scoringPlayers = Object.fromEntries(
                Object.entries(playerMap).filter(([key, value]) =>
                    value.conversions > 0 || value.tries > 0 || value.penalties > 0 || value.dropGoals > 0
                )
            )
            Object.values(scoringPlayers).forEach(p => this.calculatePoints(p));

            return scoringPlayers;
        },
        calculatePoints(playerObj) {
            const TRY_SCORE = 5;
            const PENALTY_SCORE = 3;
            const DROP_GOAL_SCORE = 3;
            const CONVERSION_SCORE = 2;

            let total = 0;
            total += playerObj.conversions * CONVERSION_SCORE;
            total += playerObj.tries * TRY_SCORE;
            total += playerObj.dropGoals * DROP_GOAL_SCORE;
            total += playerObj.penalties * PENALTY_SCORE;
            playerObj.points = total;
        },
        createMatchResult(matchData, teamEqId) {
            const thisTeamData = matchData.team1.teamEqId === teamEqId ? matchData.team1 : matchData.team2;
            const otherTeamData = matchData.team2.teamEqId !== teamEqId ? matchData.team2 : matchData.team1;
            const thisTeamScore = thisTeamData.score;
            const otherTeamScore = otherTeamData.score;
            if (thisTeamScore === otherTeamScore) {
                return {
                    result: `D vs ${otherTeamData.teamCode} ${thisTeamScore} – ${otherTeamScore}`
                };
            }
            if (thisTeamScore > otherTeamScore) {
                return {
                    result: `W vs ${otherTeamData.teamCode} ${thisTeamScore} – ${otherTeamScore}`
                };
            } else {
                return {
                    result: `L vs ${otherTeamData.teamCode} ${thisTeamScore} – ${otherTeamScore}`
                };
            }
        },
        checkBreakpoint() {
            const mediaQuery1 = window.matchMedia('(max-width: 1100px)');
            const mediaQuery2 = window.matchMedia('(max-width: 650px)');
            this.isSmallOrBelow = mediaQuery1.matches;
            this.isVSmallOrBelow = mediaQuery2.matches;
        },
    },
    mounted() {
        this.checkBreakpoint();
        window.addEventListener('resize', this.checkBreakpoint);
        const style = document.createElement('style');
        style.innerHTML = `
        @media print {
            @page {
                size: landscape;
            }
        }
    `;
    document.head.appendChild(style);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkBreakpoint);
        const styles = document.querySelectorAll('style');
        styles.forEach((s) => {
            if (s.innerHTML.includes('size: landscape')){
                s.remove();
            }
        })
    },
};
</script>
