<template>
    <div class="pageContent">
        <div class="d-flex flex-row justify-content-between">
            <div>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat mb-5"
                    v-bind:to="backToTournamentDetailsLink"
                >
                    <icon type="arrow-left" class="align-middle"/> {{ $t('backToPreviousScreen') }}
                </actionButton>
            </div>
        </div>
        <simpleHeader v-bind:title="$t('overview')">
            <template v-slot:title>
                <div class="d-flex flex-row w-100 justify-content-between">
                    <div>
                        <h1>{{ $t('overview')}}</h1>
                    </div>
                    <div>
                        <actionButton variant="outline-primary" class="btn-flat" v-on:click="printSegment()">
                            {{ $t('print') }}
                        </actionButton>
                    </div>
                </div>
            </template>
            <div class="w-100 pt-3 d-flex flex-column align-items-center">
                <div class="d-flex align-items-center gap-15">
                    <img
                        v-bind:src="logo"
                        v-bind:alt="tournament.name"
                        v-bind:height="logoNotProvided ? 40 : 80"
                    />
                    <h2>{{ tournament.name }}</h2>
                </div>
                <span>{{ $fd(tournament.dateFrom) }} - {{ $fd(tournament.dateUntil) }}</span>
            </div>
        </simpleHeader>

        <wrapper v-bind:waitFor="apiData.standings" v-bind:waitForRetry="reloadTournamentStandings"
            noPadding noLine noShadow waitSilent>
            <b-row>
                <b-col v-for="(team, index) in poolStats" v-bind:key="index" cols="12" md="4">
                    <poolTable v-bind:teams="team.stats" v-bind:poolName="team.pool"/>
                </b-col>
            </b-row>
        </wrapper>
        <b-row>
            <b-col cols="12" md="4">
                <wrapper v-bind:waitFor="apiData.matches" v-bind:waitForRetry="reloadTournamentMatches"
            noPadding noLine noShadow waitSilent>
                <matchResultsTable v-bind:matches="poolMatches" title="Pool Phase" isPoolPhase />
            </wrapper>
            </b-col>
            <b-col cols="12" md="4">
                <wrapper v-bind:waitFor="apiData.standings" v-bind:waitForRetry="reloadTournamentStandings"
                noPadding noLine noShadow waitSilent>
                    <standingsTable v-bind:teams="overallStandings" />
                </wrapper>
            </b-col>
            <b-col cols="12" md="4">
                <matchResultsTable v-bind:matches="knockoutMatches" title="Knockout Phase" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import icon from '@/components/icon.vue';
import simpleHeader from '@/components/simpleHeader.vue';
import TournamentModel from '@/models/tournament.js';
import MatchModel from '@/models/match.js';
import wrapper from '@/components/wrapper.vue';
import Model from '@/models/model.js';
import { empty } from '@/utils/miscUtils';
import TournamentStandingModel from '@/models/tournamentStanding.js';
import { RequestError } from '@/errors.js';
import poolTable from '@/components/tables/poolTable.vue';
import matchResultsTable from '@/components/tables/matchResultsTable.vue';
import standingsTable from '@/components/tables/standingsTable.vue';

export default {
    name: 'commonTournamentOverview',
    extends: base,
    components: {
        icon,
        simpleHeader,
        wrapper,
        poolTable,
        matchResultsTable,
        standingsTable
    },
    props: {
        dataTournamentId: {
            type: Number,
            mandatory: true,
            default: null
        },
    },
    data() {
        this.apiData = {
            tournament: this.loadTournament(),
        };
        return {
            apiData: {
                tournament: this.apiData.tournament,
                standings: this.loadStandings(),
                matches: this.loadTournamentMatches(),
            },
            tournament: null,
            tournamentStandings: [],
            tournamentMatches: [],
            logo: '',
        }
    },
    methods: {
        loadTournament() {
            return this.$api.call.tournament.tournamentGetById(this.dataTournamentId).then(data => {
                this.tournament = new TournamentModel(data);
                this.logo = (!empty(this.tournament.logo)) ? Model._api.call.tournament.resolveStoragePath(this.tournament.logo) : require('@/assets/img/tournament.svg');
                this.reloadTournamentStandings();
                this.reloadTournamentMatches();
            });
        },
        loadStandings() {
            //request requires the tournament eqid, so until the tournament is loaded we use a promise that never resolves
            if(!this.tournament){
                return new Promise((resolve, reject) => this.apiData.tournament.catch(reject));
            }

            return this.$api.call.tournament.tournamentStandingsGetByTournamentEqId(this.tournament.eqId)
                .then(response => {
                    if (response) {
                        this.tournamentStandings = response.map(data => new TournamentStandingModel(data));
                    }
                }, err => {
                    //if request fails with 404 error, interpret as no results found
                    if(err instanceof RequestError && err.status === 404){
                        return;
                    }

                    throw err;
                }
            );
        },
        reloadTournamentStandings(){
            this.apiData.standings = this.loadStandings();
        },
        loadTournamentMatches() {
            if (!this.tournament) {
                return new Promise((resolve, reject) => this.apiData.tournament.catch(reject))
            }
            return this.$api.call.match.matchTournamentsGetByTournamentEqId(this.tournament.eqId).then(tournamentMatches => {
                this.tournamentMatches = tournamentMatches.map(tournamentMatch => new MatchModel(tournamentMatch, this.dataTournamentId));
            })
        },
        reloadTournamentMatches() {
            this.apiData.matches = this.loadTournamentMatches();
        }
    },
    computed: {
        poolStats() {
            return this.tournamentStandings.map((standing) => ({pool: standing.name, stats: standing.table}));
        },
        poolMatches() {
            return this.tournamentMatches.filter((match) => match.stageName === "Pool");
        },
        knockoutMatches() {
            return this.tournamentMatches.filter((match) => match.stageName !== "Pool");
        },
        backToTournamentDetailsLink() {
            return {
                name: this.detailLinkRoute || 'commonTournamentDetail',
                params: Object.assign({
                    dataTournamentId: this.dataTournamentId,
                }, this.detailLinkParams),
            }
        },
        logoNotProvided() {
            if (empty(this.tournamentData)) return true;
            return empty(this.tournamentData?.tournamentLogoUrl);
        },
        overallStandings() {
            let standings = [];
            this.tournamentStandings.forEach((standing) => {
                const teamStandings = standing.table;
                teamStandings.forEach((ts) => {
                    ts.pool = standing.name;
                })
                standings = standings.concat(teamStandings);
            });
            return standings.sort((a, b) => {
                if (b.pd !== a.pd) {
                    return b.pd - a.pd;
                }

                if (b.td !== a.td) {
                    return b.td - a.td;
                }

                if (b.pts !== a.pts) {
                    return b.pts - a.pts;
                }

                if(b.tf !== a.tf) {
                    return b.tf - a.tf;
                }

                return 0;
            });
        }
    },
    mounted() {
        const style = document.createElement('style');
        style.innerHTML = `
            @media print {
                @page {
                    size: landscape;
                }
            }
        `;
    document.head.appendChild(style);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkBreakpoint);
        const styles = document.querySelectorAll('style');
        styles.forEach((s) => {
            if (s.innerHTML.includes('size: landscape')){
                s.remove();
            }
        })
    },
}
</script>
