<template>
    <b-table
        class="m-0 sevensTeamsheetTable tablePrinted"
        v-bind:fields="tableFields"
        v-bind:tbody-tr-class="'teamsheetRow'"
        v-bind:items="items"
        thead-class="text-uppercase"
        responsive hover
    >
        <!-- eslint-disable-next-line -->
        <template #head(number)="data">
            <img v-bind:src="logoUrl" height="20" width="20" v-if="teamLogoUrl" />
        </template>
        <template #cell(number)="data">
            {{ data.item.number }}
        </template>
        <template #cell(name)="data">
            <span class="no-wrap">{{ data.item.name }}</span>
        </template>
        <template #cell(position)="data">
            <div
                class="d-flex justify-content-center"
                v-bind:class="{ replacement: data.item.position === 'R'}"
            >
                {{ data.item.position }}
            </div>
        </template>
        <template #cell(age)="data">
            <span v-bind:class="{'no-wrap': true, 'text-danger': data.item.isUnderage}">
                {{ data.item.age }}
            </span>
        </template>
        <!-- eslint-disable-next-line -->
        <template #cell(yellowCards)="data">
            <div class="w-100 h-100 d-flex justify-content-center">
                <span>
                    <penaltyCard v-bind:quantity="data.item.yellowCards" v-if="data.item.yellowCards > 0" />
                </span>
            </div>
        </template>
        <!-- eslint-disable-next-line -->
        <template #cell(redCards)="data">
            <div class="w-100 h-100 d-flex justify-content-center">
                <span>
                    <penaltyCard isRedCard v-bind:quantity="data.item.redCards" v-if="data.item.redCards > 0" />
                </span>
            </div>
        </template>
    </b-table>
</template>

<script>
import { calculateAge } from '@/utils/dateUtils';
import { empty } from '@/utils/miscUtils';
import Model from '@/models/model.js';
import penaltyCard from '@/components/penaltyCard.vue';

export default {
    name: 'teamsheetTournamentTable7s',
    components: {
        penaltyCard
    },
    props: {
        players: {
            type: Array,
            required: true,
        },
        isSub: {
            type: Boolean,
            required: false,
            default: false,
        },
        scoreData: {
            type: Object,
            required: false
        },
        disciplinaries: {
            type: Object,
            required: false
        },
        matchKickOffDate: {
            type: Date,
            required: true
        },
        teamName: {
            type: String,
            required: true,
        },
        teamLogoUrl: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            tableFields: [
                {
                    key: 'number',
                    label: '',
                    class: 'text-center',
                },
                {
                    key: 'name',
                    label: `${this.teamName}`,
                    thClass: 'tableTeamText',
                },
                {
                    key: 'position',
                    label: '',
                    tdClass: 'position'
                },
                {
                    key: 'age',
                    label: 'Age',
                    tdClass: 'text-center',
                    thClass: 'text-center tableHeaderText vertical-align-center'
                },
                {
                    key: 'tries',
                    label: 'Try',
                    tdClass: 'text-center',
                    thClass: 'text-center tableHeaderText vertical-align-center'
                },
                {
                    key: 'points',
                    label: 'Pts',
                    tdClass: 'text-center',
                    thClass: 'text-center tableHeaderText vertical-align-center'
                },
                {
                    key: 'conversions',
                    label: 'Con',
                    tdClass: 'text-center',
                    thClass: 'text-center tableHeaderText vertical-align-center'
                },
                {
                    key: 'yellowCards',
                    label: 'YC',
                    tdClass: 'text-center',
                    thClass: 'text-center tableHeaderText vertical-align-center fit-width'
                },
                {
                    key: 'redCards',
                    label: 'RC',
                    tdClass: 'text-center',
                    thClass: 'text-center tableHeaderText vertical-align-center fit-width'
                },
            ],
        }
    },
    methods: {
        formatTableData(players, isSub = false) {
            const map = {};
            for (let player of players) {
                const number = parseInt(player.positionNumber) || this.getDisplayNumber(player);
                if (!number) {
                    continue;
                }

                map[number] = player;
            }
            let formattedPlayers = Object.entries(map).sort(([number1], [number2]) => number1 - number2).map(([key, player]) => player)
                .map((data) => {
                    const playerEqId = data?.eqId;
                    const playerScoreData = this.scoreData[playerEqId];
                    return {
                        number: typeof data?.positionNumber === 'number' ? parseInt(data?.positionNumber) : this.getDisplayNumber(data),
                        name: this.formatName(data),
                        age: calculateAge(data.dateOfBirth, this.matchKickOffDate),
                        conversions: (playerEqId && !empty(playerScoreData)) ? playerScoreData.conversions : 0,
                        tries: (playerEqId && !empty(playerScoreData)) ? playerScoreData.tries : 0,
                        points: (playerEqId && !empty(playerScoreData)) ? playerScoreData.points : 0,
                        position: this.getPositionLetter(data),
                        yellowCards: ((playerEqId && this.disciplinaries[playerEqId]) ? this.disciplinaries[playerEqId].yellowCards : 0),
                        redCards: ((playerEqId && this.disciplinaries[playerEqId]) ? this.disciplinaries[playerEqId].redCards : 0),
                        replacement: isSub ? data?.getPlayerAttribute('isUsedReplacement') : data.getPlayerAttribute('isReplaced'),
                    }
                });
            return formattedPlayers;
        },
        formatName(teamsheet) {
            if (!teamsheet) {
                return '';
            }

            let name = teamsheet.getName();
            if(teamsheet?.getPlayerAttribute('isCaptain')) {
                name += ' (C)';
            }
            return name;
        },
        getDisplayNumber(person) {
            if (person && (person.getPlayerAttribute('shirtNumber') || person.getPlayerAttribute('squadNumber'))) {
                return '' + (person.getPlayerAttribute('shirtNumber') || person.getPlayerAttribute('squadNumber') || '');
            }

            if (person?.number) {
                return '' + person.number;
            }

            return '';
        },
        getPositionLetter(person) {
            const position = person.positionNumber;
            switch(position) {
                case 'Fwd':
                    return 'F';
                case 'Bck':
                    return 'B';
                case 'Rep':
                    return 'R';
                default:
                    return '';
            }
        }
    },
    computed: {
        items() {
            return this.formatTableData(this.players, this.isSub);
        },
        logoUrl() {
            if (!empty(this.teamLogoUrl)) {
                return Model._api.call.union.resolveStoragePath(this.teamLogoUrl);
            }
            return null;
        }
    }
}
</script>
